import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: 'https://eb95b1465b5346148828776acb40c62c@o510915.ingest.sentry.io/6105269',
  release: process.env.SENTRY_RELEASE,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: ['www.stdcheck.local', 'stdcheck.com'],
  integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      order_id: localStorage.getItem('transaction_id') ?? null,
    };

    return event;
  },
  ignoreErrors: ['BraintreeError: Cannot contact the gateway at this time.'],
});
